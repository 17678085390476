/* eslint-disable max-len */
import React from 'react';

export default function IconSkateboarding() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <g>
        <path d="M41.78 37.77c-.06-.46-.24-.9-.51-1.28l-4.81-6.66-.05.04v-.04h.05l.07-.59.09-.61.65-4.28.2-1.32 1.47 1.6c1.09 1.17 2.75 1.62 4.29 1.17l7.14-1.64-1.94-2.74-6.52 1.18-4.08-6.17a3.32 3.32 0 00-.26-.36 4.778 4.778 0 00-3.82-1.81c-.56-.02-1.1.07-1.6.25h-.01c-.08.03-.16.07-.24.1l-2.8 1.58-3.15 1.77-.68.38c-1.54.87-2.18 2.07-2.94 3.47l-3.29 6.03 3.49.17 3.42-5.47 2.81-1.75.24-.15-.05.34-1.09 7.82h.04v.02l-.04-.02-5.28 8.18-10.72.5 1.95 3.55 11.18-.17c.61 0 .91-.33 1.06-.48l5.99-6.99 5.3 5.78 2.01 11.02 4.1.11-1.69-12.51zM34.99 13.09a4.296 4.296 0 10.5-8.58 4.296 4.296 0 10-.5 8.58z"></path>
        <path d="M28.13 52H47.129999999999995V54H28.13z"></path>
        <circle cx="28" cy="58" r="2"></circle>
        <circle cx="48" cy="58" r="2"></circle>
        <path d="M49.33 54h-21.4c-2.13 0-3.8-1.32-3.8-3h2c0 .47.77 1 1.8 1h21.4c1.03 0 1.8-.53 1.8-1h2c0 1.68-1.67 3-3.8 3z"></path>
      </g>
    </svg>
  );
}
