export default function IconOutdoorActivities() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      aria-hidden="true"
    >
      <polygon points="30.07 24.48 25.65 20.05 22.92 22.78 30.07 29.94 30.07 24.48" />
      <polygon points="26.92 34.2 24.2 36.93 30.07 42.81 30.07 37.35 26.92 34.2" />
      {/* eslint-disable-next-line max-len */}
      <path d="M45.5,30.34c0-.95.63-1.77,1.67-3C48.43,25.74,50,23.8,50,20.9c0-5-4.23-7-6.51-8a10.94,10.94,0,0,1-1.3-.68A9.07,9.07,0,0,1,40.6,10C39.16,7.59,37,4,32,4s-7.15,3.62-8.53,6A8.52,8.52,0,0,1,22,12.18a8.78,8.78,0,0,1-1.34.69C18.3,13.94,14,15.93,14,20.9c0,2.9,1.57,4.84,2.83,6.4,1,1.27,1.67,2.09,1.67,3a2.89,2.89,0,0,1-.79,1.86,8.07,8.07,0,0,0-1.77,5.18c0,4.35,3.65,7.06,7.18,9.68s7,5.16,7,9.11h0V60h3.86V56.17c0-4,3.37-6.46,7-9.11s7.18-5.33,7.18-9.68a8.07,8.07,0,0,0-1.77-5.18A2.89,2.89,0,0,1,45.5,30.34Zm-2.35,4.09a4.37,4.37,0,0,1,1.05,3c0,2.4-2.6,4.34-5.62,6.58a41.61,41.61,0,0,0-4.65,3.81L33,47H31l-.93.77A41.61,41.61,0,0,0,25.42,44c-3-2.24-5.62-4.18-5.62-6.58a4.37,4.37,0,0,1,1.05-3,6.52,6.52,0,0,0,1.51-4.09c0-2.36-1.35-4-2.53-5.48-1.06-1.3-2-2.43-2-4s.32-2.64,4.38-4.52a10.49,10.49,0,0,0,2.13-1.18A11.05,11.05,0,0,0,26.81,12C28.27,9.41,29.35,7.86,32,7.86S35.75,9.41,37.3,12a11.41,11.41,0,0,0,2.51,3.24,11,11,0,0,0,2,1.13c2.51,1.18,4.29,2.2,4.29,4.56,0,1.53-.91,2.66-2,4C43,26.32,41.64,28,41.64,30.34A6.52,6.52,0,0,0,43.15,34.43Z" />
      <polygon points="33.93 37.35 33.98 42.76 39.8 36.93 37.08 34.2 33.93 37.35" />
      <polygon points="33.93 24.48 33.93 29.94 41.08 22.78 38.35 20.05 33.93 24.48" />
      <rect x="30" y="16" width="4" height="44" />
    </svg>
  );
}
