export default function IconSkiing() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      aria-hidden="true"
    >
      <circle cx="37" cy="15" r="5" />
      <rect x="5" y="35" width="42" height="2" />
      <path d="M56.32,51.59l-1.23,1.23A4,4,0,0,1,52.25,54H5v4H52.25a8,8,0,0,0,5.67-2.35l1.23-1.24Z" />
      {/* eslint-disable-next-line max-len */}
      <path d="M36,22l0,0h0a4.91,4.91,0,0,0-1.37-1.27,5,5,0,0,0-6.88,1.64l-8,13a5,5,0,0,0,1.2,6.54c.06.08,5.72,3.76,5.72,3.76l-6.14,10,1.4.86a5.35,5.35,0,0,0,7.36-1.75l4.42-7.19a4.89,4.89,0,0,0,.42-4.37,5.23,5.23,0,0,0-2.23-2.57l-2.67-1.6,6.7-10.88a40.45,40.45,0,0,0,10.49,10L48,35C41.78,31,36.25,22.34,36,22Z" />
    </svg>
  );
}
